exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-about-us-js": () => import("./../../../src/templates/about-us.js" /* webpackChunkName: "component---src-templates-about-us-js" */),
  "component---src-templates-blog-index-js": () => import("./../../../src/templates/blog-index.js" /* webpackChunkName: "component---src-templates-blog-index-js" */),
  "component---src-templates-blog-pages-js": () => import("./../../../src/templates/blog-pages.js" /* webpackChunkName: "component---src-templates-blog-pages-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */),
  "component---src-templates-mattress-js": () => import("./../../../src/templates/mattress.js" /* webpackChunkName: "component---src-templates-mattress-js" */),
  "component---src-templates-product-mattress-js": () => import("./../../../src/templates/product-mattress.js" /* webpackChunkName: "component---src-templates-product-mattress-js" */),
  "component---src-templates-raiting-brand-pages-js": () => import("./../../../src/templates/raiting-brand-pages.js" /* webpackChunkName: "component---src-templates-raiting-brand-pages-js" */),
  "component---src-templates-search-js": () => import("./../../../src/templates/search.js" /* webpackChunkName: "component---src-templates-search-js" */),
  "component---src-templates-site-map-js": () => import("./../../../src/templates/site-map.js" /* webpackChunkName: "component---src-templates-site-map-js" */)
}

